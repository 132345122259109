export default {
  API: 'API',
  SERVICES: {
    BRAINTREE: 'BRAINTREE',
    FRONTEND: 'FRONTEND',
    KUSTOMER: 'KUSTOMER',
    SLI: 'SLI',
    SOLIDUS: 'SOLIDUS',
    STRAPI: 'STRAPI',
    KLAVIYO: 'KLAVIYO',
  }
};
