import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

const ProductContext = createContext();

const productReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_WARNING_MESSAGE': {
      return {
        ...state,
        warning: action.payload
      };
    }

    case 'UPDATE_QUANTITY': {
      return {
        ...state,
        quantityChange: action.payload
      };
    }

    case 'UPDATE_BUTTON_TEXT': {
      return {
        ...state,
        buttonText: action.payload
      };
    }

    case 'SET_STICKY_ADDED_TO_CART': {
      return {
        ...state,
        stickyAddedToCart: action.payload
      };
    }

    case 'SET_ONPAGE_ADDED_TO_CART': {
      return {
        ...state,
        onPageAddedToCart: action.payload
      };
    }

    case 'SET_ADD_MONOGRAM': {
      return {
        ...state,
        addMonogram: action.payload
      };
    }

    case 'SET_REQUIRE_MONOGRAM': {
      return {
        ...state,
        requireMonogram: action.payload
      };
    }

    case 'SET_MONOGRAM_COLOR': {
      return {
        ...state,
        monogramColor: action.payload
      };
    }

    case 'SET_MONOGRAM_FONT': {
      return {
        ...state,
        monogramFont: action.payload
      };
    }

    case 'SET_MONOGRAM_TEXT': {
      return {
        ...state,
        monogramText: action.payload
      };
    }

    case 'RESET_MONOGRAM_FIELDS': {
      return {
        ...state,
        monogramColor: false,
        monogramFont: false,
        monogramText: false
      }
    }

    case 'SET_MISSING_MONOGRAM_FIELDS': {
      return {
        ...state,
        ...action.payload
      };
    }

    case 'RESET_MISSING_MONOGRAM_FIELDS': {
      return {
        ...state,
        missingMonogramColor: false,
        missingMonogramFont: false,
        missingMonogramText: false
      };
    }

    case 'SET_WISHLIST_REF': {
      return {
        ...state,
        wishlistRef: action.payload
      };
    }

    case 'SET_MONOGRAM_FORM_REF': {
      return {
        ...state,
        monogramFormRef: action.payload
      };
    }

    case 'SET_ONPAGE_CTA_REF': {
      return {
        ...state,
        onPageCTARef: action.payload
      };
    }

    case 'SET_ADDED_OPTIONAL_MONOGRAM': {
      return {
        ...state,
        addedOptionalMonogram: action.payload
      };
    }

    case 'SET_PRODUCT_ADDED': {
      return {
        ...state,
        productVariantAdded: action.payload
      };
    }

    case 'SET_PRODUCT_ADDED_MODAL_ACTIVE': {
      return {
        ...state,
        productAddedModalActive: action.payload
      };
    }

    default: {
      throw new Error(`Unhandled action type in ProductProvider: ${action.type}`);
    }
  }
};

const initialState = {
  warning: '',
  quantityChange: '1',
  buttonText: 'Add to bag',
  onPageAddedToCart: false,
  addMonogram: false,
  requireMonogram: false,
  monogramColor: false,
  monogramFont: false,
  monogramText: false,
  missingMonogramColor: false,
  missingMonogramFont: false,
  missingMonogramText: false,
  wishlistRef: null,
  monogramFormRef: null,
  onPageCTARef: null,
  addedOptionalMonogram: false,
  productVariantAdded: null,
  productAddedModalActive: false
};

const ProductProvider = ({ children, isPaidPDP }) => {
  const [state, dispatch] = useReducer(productReducer, initialState);

  const updateWarningMessage = (message) => dispatch({
    type: 'UPDATE_WARNING_MESSAGE',
    payload: message
  });

  const updateQuantity = (quantity) => {
    if (Number(quantity) > 1) {
      dispatch({
        type: 'UPDATE_QUANTITY',
        payload: quantity
      });
    }
  };

  const updateButtonText = (text) => dispatch({
    type: 'UPDATE_BUTTON_TEXT',
    payload: text
  });

  const onStickyAddedToCart = (boolean) => dispatch({
    type: 'SET_STICKY_ADDED_TO_CART',
    payload: boolean
  });

  const onOnPageAddedToCart = (boolean) => dispatch({
    type: 'SET_ONPAGE_ADDED_TO_CART',
    payload: boolean
  });

  const setAddMonogram = (boolean) => dispatch({
    type: 'SET_ADD_MONOGRAM',
    payload: boolean
  });

  const setRequireMonogram = (boolean) => dispatch({
    type: 'SET_REQUIRE_MONOGRAM',
    payload: boolean
  });

  const setMonogramColor = (color) => dispatch({
    type: 'SET_MONOGRAM_COLOR',
    payload: color
  });

  const setMonogramFont = (font) => dispatch({
    type: 'SET_MONOGRAM_FONT',
    payload: font
  });

  const setMonogramText = (text) => dispatch({
    type: 'SET_MONOGRAM_TEXT',
    payload: text
  });

  const resetMonogramFields = () => dispatch({
    type: 'RESET_MONOGRAM_FIELDS'
  });

  const setMissingMonogramFields = (payload) => dispatch({
    type: 'SET_MISSING_MONOGRAM_FIELDS',
    payload
  });

  const resetMissingMonogramFields = () => dispatch({
    type: 'RESET_MISSING_MONOGRAM_FIELDS'
  });

  const setAddedOptionalMonogram = (boolean) => dispatch({
    type: 'SET_ADDED_OPTIONAL_MONOGRAM',
    payload: boolean
  });

  const resetMonogram = () => {
    dispatch({ type: 'SET_ADD_MONOGRAM', payload: false });
    dispatch({ type: 'SET_REQUIRE_MONOGRAM', payload: false });
    dispatch({ type: 'SET_ADDED_OPTIONAL_MONOGRAM', payload: false });
    dispatch({ type: 'RESET_MONOGRAM_FIELDS' });
  }

  const handleMonogramOnly = () => {
    dispatch({ type: 'SET_ADD_MONOGRAM', payload: true });
    dispatch({ type: 'SET_REQUIRE_MONOGRAM', payload: true });
    dispatch({ type: 'RESET_MONOGRAM_FIELDS' });
  };

  const handleMonogramClick = (addMonogram) => {
    dispatch({ type: 'SET_ADD_MONOGRAM', payload: !addMonogram });
    dispatch({ type: 'UPDATE_WARNING_MESSAGE', payload: ''});
    dispatch({ type: 'SET_REQUIRE_MONOGRAM', payload: !addMonogram });
    dispatch({ type: 'SET_ADDED_OPTIONAL_MONOGRAM', payload: false });

    if (!addMonogram) {
      dispatch({ type: 'SET_REQUIRE_MONOGRAM', payload: !addMonogram });
      dispatch({ type: 'SET_ADDED_OPTIONAL_MONOGRAM', payload: true });
      dispatch({ type: 'RESET_MONOGRAM_FIELDS' });
    }
  }

  const setWishListRef = (ref) => dispatch({
    type: 'SET_WISHLIST_REF',
    payload: ref
  });

  const setMonogramFormRef = (ref) => dispatch({
    type: 'SET_MONOGRAM_FORM_REF',
    payload: ref
  });

  const setOnPageCTARef = (ref) => dispatch({
    type: 'SET_ONPAGE_CTA_REF',
    payload: ref
  });

  const setProductAdded = (selectedVariantId) => dispatch({
    type: 'SET_PRODUCT_ADDED',
    payload: selectedVariantId
  });

  const setProductAddedModalActive = (boolean) => dispatch({
    type: 'SET_PRODUCT_ADDED_MODAL_ACTIVE',
    payload: boolean
  });

  const value = {
    state,
    updateWarningMessage,
    updateQuantity,
    updateButtonText,
    onStickyAddedToCart,
    setAddMonogram,
    setRequireMonogram,
    handleMonogramOnly,
    handleMonogramClick,
    setMonogramColor,
    setMonogramFont,
    setMonogramText,
    resetMonogram,
    resetMonogramFields,
    setMissingMonogramFields,
    resetMissingMonogramFields,
    setWishListRef,
    setMonogramFormRef,
    setOnPageCTARef,
    onOnPageAddedToCart,
    setAddedOptionalMonogram,
    setProductAdded,
    setProductAddedModalActive,
    isPaidPDP,
    dispatch
  };

  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};

ProductProvider.defaultProps = {
  isPaidPDP: false
};

ProductProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isPaidPDP: PropTypes.bool
};

const useProduct = () => {
  const context = useContext(ProductContext);

  if (context === undefined) {
    throw new Error('useProduct must be used within a ProductProvider');
  }

  return context;
};

export { ProductProvider, useProduct };
