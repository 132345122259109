import { GrowthBook } from '@growthbook/growthbook-react';

const growthbook = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_SECRET,
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key
    });
  }
});


export const GROWTHBOOK_FEATURES_ENDPOINT = `${growthbook._ctx.apiHost}/api/features/${growthbook._ctx.clientKey}`;

export default growthbook;
