import produce from '../../produce';

import * as types from './types';
// fetching this CMS data during SSR to avoid flickering on initial mount
import { promotions as staticPromotionContent } from '../../../cms-generals';

const initialState = {
  content: staticPromotionContent,
  showPromotion: true
};

/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
const reducer = produce((draft, action) => {
  switch (action.type) {
    case types.PROMOTIONS_SET_PROMOS:
      draft.content = action.payload.data.attributes.entries;
      break;
    case types.SHOW_PROMOTION:
      draft.showPromotion = action.payload;
      break;
  }
}, initialState);

export default reducer;
