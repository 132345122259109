/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/nextjs';
import { unformat } from 'accounting-js';
import getCookie from './getCookie';
import userEmail from './trackingHelpers';
import { aa } from './algolia';
import { getPaymentMethod } from './paymentMethodHelpers';

const { window } = global;

const CURRENCY_CODE = 'USD';

const CHECKOUT_STEPS = {
  cart: 0,
  address: 1,
  delivery: 2,
  payment: 3,
  confirm: 4,
  complete: 5
};

const customerProperties = ({ cart, user }) => ({
  email: cart?.email,
  first_name: user?.first_name,
  last_name: user?.last_name
});

const addressFor = (address) => ({
  first_name: address.firstname,
  last_name: address.lastname,
  address1: address.address1,
  address2: address.address2,
  city: address.city,
  zip: address.zipcode,
  region: address.state?.name ?? address.state_name,
  region_code: address.state?.abbr,
  country: address.country?.iso_name,
  country_code: address.country?.iso,
  phone_number: address.phone,
  company: address.company
});

const cartProductPayload = (lineItem) => ({
  age_range: lineItem.variant?.option_values?.[0].name,
  brand: lineItem.variant?.brand,
  image_url: lineItem.variant.images?.[0]?.product_url,
  price: lineItem.price,
  product_id: lineItem?.variant?.master_sku,
  product_name: lineItem.variant.name,
  quantity: lineItem.quantity,
  sku: lineItem.variant.sku,
  vendor: lineItem.vendor_name,
  url: `/product/${lineItem.variant.slug}`
});

const productViewPayload = (product) => ({
  age_range: product?.classifications.find((c) => c.taxon.permalink.match(/^age-range/))?.taxon?.name,
  brand: product?.brand,
  categories: product?.breadcrumb_taxons?.map((t) => t.name),
  gender: product?.classifications.find((c) => c.taxon.permalink.match(/^gender/))?.taxon?.name,
  image_url: product.master?.images?.[0]?.product_url,
  main_category: product?.breadcrumb_taxons?.length > 0
    ? product?.breadcrumb_taxons?.[product?.breadcrumb_taxons?.length - 1]?.name
    : null,
  price: unformat(product.display_price),
  product_id: product.master.sku,
  product_name: product.name,
  product_type: product?.classifications.filter((c) => c.taxon.permalink.match(/^type/)).map((x) => x?.taxon?.name),
  sku: product.master.sku,
  skus: product?.variants?.map((v) => v.sku),
  trends: product?.trends?.map((t) => t.value),
  url: `/product/${product.slug}`
});

const productPayload = (product, quantity, variant) => {
  if (!product || !variant) return null;

  const payload = {
    ...productViewPayload(product),
    image_url: variant.images?.[0]?.product_url,
    price: variant.price,
    product_id: product?.master?.sku,
    product_name: variant.name,
    quantity,
    sku: variant.sku,
    vendor: variant.stock_items?.[0].stock_location_name
  };

  return payload;
};

// TODO: confirm with data team on all fields
const productViewPayloadAlgolia = (product) => ({
  brand: product?.brand,
  // main_category: product?.main_category || product?.category, //TODO what is this supposed to be?
  image_url: product?.image,
  price: product?.price_min,
  // product_id: product?.maisonette_sku, // TODO: we don't have a SKU in algolia schema
  product_name: product.title,
  sku: product?.manufacturer_id, // TODO confirm this is correct. this is what we track to amplitude
  trends: product?.trends,
  url: `/product/${product?.slug}`
});

const addToCartPayload = ({
  cart, product, quantity, variant
}) => ({
  ...productPayload(product, quantity, variant),
  brands: cart.line_items.map((li) => li.variant?.brand)?.reverse(),
  checkout_url: `${global.window.location.origin}/checkout`,
  item_names: cart.line_items.map((li) => li.variant.name)?.reverse(),
  items: cart.line_items.map((li) => cartProductPayload(li))?.reverse(),
  value: cart.subtotals?.order_total

});

const checkoutPayload = (cart) => ({
  $event_id: `${cart.id}_${+new Date()}`,
  $value: cart.subtotals?.order_total,
  items: cart.line_items?.map((li) => cartProductPayload(li)),
  checkout_url: `${global.window.location.origin}/checkout`,
  item_names: cart.line_items?.map((li) => li.variant.name)
});

const checkoutStepPayload = ({ products, step }) => ({
  actionField: { step: CHECKOUT_STEPS[step] },
  products: products?.map((product) => ({
    name: product.variant.name,
    id: product.variant.sku,
    price: product.variant.price,
    brand: product.variant?.brand,
    variant: product.variant?.option_values?.[0]?.name,
    quantity: 1
  }))
});

/** ****************************** */

const KLAVIYO = {
  addToCart: (productData, customerData) => ([
    'track',
    'Added to Cart',
    { ...productData, ...customerData }
  ]),
  viewProduct: (productData) => ([
    'track',
    'Viewed Product',
    productData
  ]),
  checkout: (orderData, customerData) => ([
    'track',
    'Started Checkout',
    { ...orderData, ...customerData }
  ]),
  user: ({
    isLoggedIn, email, first_name, last_name
  }) => ([
    'identify',
    {
      $source: isLoggedIn ? 'authenticated' : null,
      $email: email,
      $first_name: first_name,
      $last_name: last_name
    }
  ]),
  track: (data) => {
    if (typeof global.window.klaviyo === 'undefined' || !global.window.klaviyo) {
      console.warn('Klaviyo missing');
      return null;
    }

    return global.window.klaviyo.push(data);
  }
};

const customAttributeForTracking = () => {
  const unixTimestamp = Math.round((new Date()).getTime() / 1000);
  const sessionToken = getCookie('maisonette_session_token');
  const userData = getCookie('maisonette_user_data');
  const user = userData ? JSON.parse(userData) : null;

  return {
    ga_active: !!(global.window.ga && global?.ga?.q),
    ga_session_id: sessionToken,
    ga_user_id: user?.id || null,
    ga_hit_timestamp: unixTimestamp
  };
};

const GTM = {
  addToCart: (productData) => ({
    event: 'addToCart',
    productName: productData.product_name,
    productCategory: productData.main_category,
    productSku: productData?.sku,
    ecommerce: {
      currency: CURRENCY_CODE,
      add: {
        products: [{
          name: productData.product_name,
          id: productData.sku,
          price: +productData.price,
          brand: productData?.brand,
          category: productData.main_category,
          variant: productData.age_range,
          quantity: +productData.quantity,
          imageUrl: productData?.image_url,
          productUrl: productData.url,
          isEmpty: false
        }]
      }
    },
    ...(customAttributeForTracking()),
    ...(userEmail())
  }),
  removeFromCart: (productData) => ({
    productName: productData.product_name,
    event: 'removeFromCart',
    ecommerce: {
      currency: CURRENCY_CODE,
      remove: {
        products: [{
          name: productData.product_name,
          price: +productData.price,
          brand: productData?.brand,
          category: '', // NOTE: can't reach this
          variant: productData.age_range,
          quantity: +productData.quantity,
          imageUrl: productData?.image_url,
          productUrl: productData.slug,
          isEmpty: true,
          currency: CURRENCY_CODE
        }]
      }
    },
    ...(customAttributeForTracking())
  }),
  productClick: (productData, position, list) => ({
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list },
        products: [{
          name: productData.product_name,
          id: productData.sku,
          price: +productData.price,
          brand: productData?.brand,
          category: productData.main_category,
          variant: productData.sku,
          position
        }]
      }
    }
  }),
  cartContents: (cartData) => ({
    event: 'cartContents',
    line_items: cartData?.line_items?.map((productData) => ({
      name: productData.product_name,
      price: +productData.price,
      brand: productData?.brand,
      category: '', // NOTE: can't reach this
      variant: productData.age_range,
      quantity: +productData.quantity,
      imageUrl: productData?.image_url,
      productUrl: productData.slug,
      isEmpty: true,
      currency: CURRENCY_CODE
    }))
  }),
  viewProduct: (productData) => ({
    event: 'ViewProduct',
    productBrand: productData?.brand,
    productSku: productData.sku,
    productSkus: productData?.skus,
    productName: productData.product_name,
    productCategory: productData.main_category,
    value: +productData.price,
    currency: CURRENCY_CODE,
    imageUrl: productData?.image_url,
    productUrl: productData.url,
    ecommerce: {
      detail: {
        products: [{
          name: productData.product_name,
          id: productData.sku,
          price: +productData.price,
          brand: productData?.brand,
          category: productData.main_category,
          variant: productData?.age_range
        }]
      }
    }
  }),
  newCustomer: (orderData) => ({
    event: 'newCustomer',
    ecommerce: {
      event: 'purchase',
      transaction_id: `${orderData.id}_${+new Date()}`,
      value: orderData.subtotals.order_total,
      currency: CURRENCY_CODE,
      new_customer: orderData.first_order
    }
  }),
  purchase: (orderData) => ({
    event: 'Purchase',
    new_customer: orderData.first_order,
    transaction_id: orderData.number,
    transactionId: orderData.number,
    transactionTotal: +orderData.payment_total,
    transactionTax: +orderData.tax_total,
    transactionShipping: +orderData.ship_total,
    transactionCurrency: CURRENCY_CODE,
    ecommerce: {
      purchase: {
        actionField: {
          id: orderData.number,
          affiliation: 'Maisonette',
          revenue: +orderData.item_total,
          value: +orderData.item_total,
          tax: +orderData.tax_total,
          discount: +orderData.adjustment_total,
          coupon: orderData?.applied_promotion_codes?.[0]?.value,
          user_id: orderData?.user_id,
          user_status: orderData?.user_id ? 'Existing' : 'New',
          shipping: +orderData.ship_total,
          email: orderData.email,
          ...addressFor(orderData.bill_address),
          action: 'purchase'
        },
        products: orderData.line_items?.map((item) => ({
          brand: item.variant?.brand,
          category: item.breadcrumb_taxons ? item.breadcrumb_taxons.map((taxon) => taxon.name).join('/') : '',
          currency: CURRENCY_CODE,
          id: item.variant.sku,
          imageUrl: item.variant?.images?.[0]?.product_url,
          name: item.variant.name,
          pre_tax_price: '', // NOTE: not in data
          price: +item.variant.price,
          productUrl: `/product/${item.variant.slug}`,
          quantity: +item.quantity,
          sku: item.variant.sku,
          variant: item.variant.option_values[0].name
        }))
      }
    },
    ...(customAttributeForTracking())
  }),
  purchaseGA4: (orderData) => ({
    event: 'purchase',
    ecommerce: {
      transaction_id: orderData.number,
      value: +orderData.item_total,
      tax: +orderData.tax_total,
      discount: +orderData.adjustment_total,
      coupon: orderData?.applied_promotion_codes?.[0]?.value,
      currency: CURRENCY_CODE,
      shipping: +orderData.ship_total,
      items: orderData.line_items?.map((item) => ({
        brand: item.variant?.brand,
        category: item.breadcrumb_taxons ? item.breadcrumb_taxons.map((taxon) => taxon.name).join('/') : '',
        currency: CURRENCY_CODE,
        id: item.variant.sku,
        imageUrl: item.variant?.images?.[0]?.product_url,
        name: item.variant.name,
        item_id: item.variant.sku,
        item_name: item.variant.name,
        pre_tax_price: '', // NOTE: not in data
        price: +item.variant.price,
        productUrl: `/product/${item.variant.slug}`,
        quantity: +item.quantity,
        sku: item.variant.sku,
        variant: item.variant.option_values[0].name
      }))
    },
    ...customAttributeForTracking()
  }),
  checkoutStep: (checkoutData) => ({
    event: 'checkout',
    ecommerce: {
      checkout: checkoutData
    },
    ...(customAttributeForTracking())
  }),
  addPaymentInfo: ({ cart, method = null, types = null }) => ({
    event: 'addPaymentInfo',
    ecommerce: {
      paymentInfo: {
        'cart id': cart?.number,
        'total cart value': cart?.total,
        'total cart size': cart?.total_quantity,
        'payment method': (() => {
          if (method) return [getPaymentMethod(null, method)];
          if (Array.isArray(types)) return types.map((x) => getPaymentMethod(x));
          return [getPaymentMethod(types)];
        })(),
        productSku: cart?.line_items?.map((li) => li?.variant?.master_sku)
      }
    },
    ...(customAttributeForTracking())
  }),
  pageType: (type, path) => ({
    event: 'pageviewEvent',
    pageName: path,
    pageType: type
  }),
  track: ({
    event = 'techEvent',
    eventCategory,
    eventAction,
    eventLabel,
    eventNonInteraction,
    ...data
  }) => {
    if (typeof global.window.dataLayer === 'undefined' || !global.window.dataLayer) {
      console.warn('dataLayer missing');
      return null;
    }

    const sessionToken = getCookie('maisonette_session_token');
    return global.window.dataLayer.push({
      event,
      ...(eventCategory && { eventCategory }),
      ...(eventAction && { eventAction }),
      ...(eventLabel && { eventLabel }),
      ...(eventNonInteraction ? { eventNonInteraction } : { eventNonInteraction: false }),
      ...(sessionToken && { maisonette_session_token: sessionToken }),
      ...data
    });
  },
  add: (data) => {
    if (typeof global.window.dataLayer === 'undefined' || !global.window.dataLayer) {
      console.warn('dataLayer missing');
      return null;
    }

    const sessionToken = getCookie('maisonette_session_token');

    return global.window.dataLayer.push({
      ...data,
      ...(sessionToken && { maisonette_session_token: sessionToken }),
      ...(userEmail())
    });
  }
};

export const LUX = {
  addedToCart: (value = 'yes') => {
    global.LUX.addData('added to cart', value);
  },
  removedFromCart: (value = 'yes') => {
    global.LUX.addData('removed from cart', value);
  },
  cartValue: (data) => {
    global.LUX.addData('cart value', data.total);
  },
  cartSize: (data) => {
    global.LUX.addData('cart size', data.total_quantity);
  },
  converted: (value = 'yes') => {
    global.LUX.addData('converted', value);
  },
  setPageType: (type) => {
    if (!type) return;
    global.LUX.label = type;
  }
};

/** ****************************** */

export const getPaymentMethodTrackingLabel = (payments) => {
  const payment = payments[payments.length - 1];
  const method = payment.payment_method.name;

  if (method === 'Credit Card') {
    return 'Credit card';
  }

  if (method === 'Store Credit') {
    return 'Store credit';
  }

  if (method === 'Braintree') {
    const paymentType = payment.source.payment_type;

    switch (paymentType) {
      case 'CreditCard':
        return 'Credit Card';
      case 'PayPalAccount':
        return 'PayPal';
      case 'ApplePayCard':
        return 'Apple Pay';
      default:
        break;
    }
  }

  return '';
};

/** ****************************** */

export const trackHomepageSignupForm = () => { };
export const trackFooterSignupForm = () => { };
export const trackAccountSubscription = () => { };

export const trackPageType = (type, path) => {
  if (path.indexOf('/preview') === 0) return;

  if (!type) {
    Sentry.withScope((scope) => {
      scope.setFingerprint(['GTM']);
      scope.setLevel('warning');
      Sentry.setContext('page', { group: type, path });
      Sentry.captureMessage('pageType is null');
    });
  }

  GTM.add(GTM.pageType(type, path));
  LUX.setPageType(type);
};

const trackCartContents = (data) => {
  GTM.add(GTM.cartContents({ line_items: data }));
  GTM.track(GTM.cartContents({ line_items: data }));
};

export const trackAddToCart = (payload) => {
  const productData = addToCartPayload(payload);
  const customerData = customerProperties(payload);
  KLAVIYO.track(KLAVIYO.addToCart(productData, customerData));
  GTM.track(GTM.addToCart(productData));
  trackCartContents(payload.cart.line_items.map((li) => cartProductPayload(li)));
};

export const trackRemoveFromCart = ({ cart, product }) => {
  GTM.track(GTM.removeFromCart(cartProductPayload(product)));
  trackCartContents(cart.line_items.map((li) => cartProductPayload(li)));
};

export const trackLineItemChange = ({ product, quantity, variant }) => {
  trackCartContents([productPayload(product, quantity, variant)]);
};

export const trackProductView = ({ product }) => {
  const productData = productViewPayload(product);
  GTM.track(GTM.viewProduct(productData));
  KLAVIYO.track(KLAVIYO.viewProduct(productData));

  /**
    TODO: missing tracking script

    ProductName: product.title,
    ProductID: product.maisonette_product_id,
    Categories: [product.main_category],
    ImageURL: product.image,
    URL: `/products/${product.slug}`,
    Metadata: {
      Brand: product.brand,
      Price: product.price_quickview
    }

  addKlaviyo(['trackViewedItem', klaviyoItem.item]);
 */
};

export const trackProductClick = ({ product, position, list }, isCartData) => {
  if (product) {
    const data = isCartData ? cartProductPayload(product) : productViewPayloadAlgolia(product);

    GTM.track(GTM.productClick(data, position, list));
  }
};

export const trackNewCustomer = (order) => {
  GTM.track(GTM.newCustomer(order));
};

export const trackCheckout = ({ cart, user }) => {
  const orderData = checkoutPayload(cart);
  const customerData = customerProperties({ cart, user });
  KLAVIYO.track(KLAVIYO.checkout(orderData, customerData));
};

export const trackCheckoutStep = (data) => {
  GTM.track(GTM.checkoutStep(checkoutStepPayload(data)));
};

export const trackPaymentInfo = (data) => {
  GTM.track(GTM.addPaymentInfo(data));
};

export const trackCheckoutOption = ({ step, option }) => {
  GTM.track({
    event: 'checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField: { step: CHECKOUT_STEPS[step], option }
      }
    }
  });
};

export const trackPurchase = ({ order }) => {
  GTM.track(GTM.purchase(order));
  GTM.track(GTM.purchaseGA4(order));
};

export const trackActiveUser = (payload) => {
  KLAVIYO.track(KLAVIYO.user(payload));
};

/** ****************************** */
// ALGOLIA TRACKING EVENTS

export const trackPurchaseCompleteAlgolia = (checkoutResData, user) => {
  const { line_items } = checkoutResData;
  const images = line_items?.map(({ variant }) => variant.images)?.flat();
  if (images && images.length === 0) return;
  const objectIDsArray = [
    ...new Set(images?.map(({ viewable_id }) => String(viewable_id)))
  ];
  const userToken = user?.id
    ? String(user?.id)
    : user?.algoliaGuestUserToken;
  const insight = {
    eventType: 'conversion',
    eventName: 'Purchase Complete',
    index: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEX,
    objectIDs: objectIDsArray,
    userToken,
    timestamp: Date.now()
  };

  if (objectIDsArray.length > 20) {
    // Each event to algolia allows maximum of 20 objectIds.
    // If there are more than 20, perform split
    while (objectIDsArray.length) {
      aa('convertedObjectIDs', {
        ...insight,
        objectIDs: objectIDsArray.splice(0, 20)
      });
    }
  } else {
    aa('convertedObjectIDs', insight);
  }
};

/** ****************************** */

/** ****************************** */
/** MovableInk Tracking Event */

const isMovableInkAvailable = () => {
  if (window.MovableInkTrack && window.MovableInkTrack === 'mitr') {
    return true;
  }
  return false;
};

export const trackMovableInkConversion = (order) => {
  if (isMovableInkAvailable()) {
    order.line_items.forEach((item) => {
      if (item.variant) {
        window.mitr('addProduct', {
          sku: item.variant.slug,
          name: item.variant.name,
          price: item.variant.price,
          quantity: item.quantity
        });
      }
    });

    // Event if promotion is applied
    if (order.applied_promotion_codes && order.applied_promotion_codes.length > 0) {
      order.applied_promotion_codes.forEach((promo) => {
        if (promo.value) {
          window.mitr('addPromo', {
            code: promo.value,
            description: promo.value
          });
        }
      });
    }

    /*
    The following call is REQUIRED, and sends the conversion event.
    The below syntax may vary based on your purchaseInfo formatting
    and tag management environment.
    */
    window.mitr('send', 'conversion', {
      revenue: order.total,
      identifier: order.number
    });
  }
};

/** ****************************** */

export default GTM.track;
